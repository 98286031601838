import { Component } from '@angular/core';
import { ButtonComponent } from '@shared/components/base/button/button.component';
import { CheckboxModule } from '@shared/components/form-elements/checkbox/checkbox.module';
import { ErrorComponent } from '@shared/components/form-elements/error/error.component';
import { FormFieldModule } from '@shared/components/form-elements/form-field/form-field.module';
import { IconComponent } from '@shared/components/base/icon/icon.component';
import { InputTextComponent } from '@shared/components/form-elements/input-text/input-text.component';
import { LabelComponent } from '@shared/components/form-elements/label/label.component';
import { MatTableModule } from '@angular/material/table';
import { NgIf } from '@angular/common';
import { NgOptionComponent, NgSelectComponent } from '@ng-select/ng-select';
import { TableControlsComponent } from '@shared/components/tables/table-controls/table-controls.component';
import { TableSortingButtonComponent } from '@shared/components/tables/table-sorting-button/table-sorting-button.component';
import { TextareaComponent } from '@shared/components/form-elements/textarea/textarea.component';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FileUploadModule } from '@shared/components/form-elements/file/file-upload/file-upload.module';
import { ToastsService } from '@shared/components/base/toasts/services/toasts.service';
import { ToastTheme } from '@shared/components/base/toasts/models/toast.model';
import { DividerComponent } from '@shared/components/base/divider/divider.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DatepickerComponent } from '@shared/components/form-elements/datepicker/datepicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TableResponsiveComponent } from '@shared/components/tables/table-responsive/table-responsive.component';

export interface PeriodicElement {
  id: string;
  name: string;
  code: string;
  hazardClass: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    id: '111',
    name: 'Наименование отходов',
    code: 'ХХХХХХХХХХХ',
    hazardClass: 'X',
  },
  {
    id: '222',
    name: 'Наименование отходов',
    code: 'ХХХХХХХХХХХ',
    hazardClass: 'X',
  },
  {
    id: '333',
    name: 'Наименование отходов',
    code: 'ХХХХХХХХХХХ',
    hazardClass: 'X',
  },
  {
    id: '345',
    name: 'Наименование отходов',
    code: 'ХХХХХХХХХХХ',
    hazardClass: 'X',
  },
  {
    id: '355',
    name: 'Наименование отходов',
    code: 'ХХХХХХХХХХХ',
    hazardClass: 'X',
  },
  {
    id: '444',
    name: 'Наименование отходов',
    code: 'ХХХХХХХХХХХ',
    hazardClass: 'X',
  },
];

@Component({
  selector: 'pn-kit',
  standalone: true,
  imports: [
    ButtonComponent,
    CheckboxModule,
    ErrorComponent,
    FormFieldModule,
    IconComponent,
    InputTextComponent,
    LabelComponent,
    MatTableModule,
    NgIf,
    NgOptionComponent,
    NgSelectComponent,

    TableControlsComponent,
    TableSortingButtonComponent,
    TextareaComponent,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    DividerComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    DatepickerComponent,
    MatFormFieldModule,
    TableResponsiveComponent,
  ],
  templateUrl: './kit.component.html',
  styleUrl: './kit.component.scss',
})
export class KitComponent {
  datepicker: FormControl = new FormControl();
  formControl: FormControl = new FormControl();
  formControl2: FormControl = new FormControl();
  formControl3: FormControl = new FormControl();
  displayedColumns: string[] = ['id', 'name', 'code', 'hazardClass', 'actions'];
  dataSource = ELEMENT_DATA;
  activeSorting: string = '';
  sortAscending: boolean = true;
  selectedCar: number = 1;

  cars = [
    {
      id: 1,
      name: 'Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo',
    },
    {
      id: 2,
      name: 'Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab',
    },
    {
      id: 3,
      name: 'Opel Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel',
    },
    {
      id: 4,
      name: 'Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi',
    },
    {
      id: 5,
      name: 'Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo Volvo',
    },
    {
      id: 6,
      name: 'Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab Saab',
    },
    {
      id: 7,
      name: 'Opel Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel  Opel',
    },
    {
      id: 8,
      name: 'Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi Audi',
    },
  ];

  constructor(private toastsService: ToastsService) {
    this.formControl = new FormControl(null, [Validators.required]);
    this.formControl2 = new FormControl(null, [Validators.required]);
    this.formControl3 = new FormControl(null, [Validators.required]);
  }

  test1() {
    console.log(1);
  }

  test2() {
    console.log(2);
  }

  testToast(theme: ToastTheme) {
    const message = this.formControl.value || 'Тестовое сообщение';
    this.toastsService.addToast(message, theme);
  }

  changeDatepicker(e: Event | string) {
    console.log(this.datepicker.value);
  }

  sorting(column: string, sortAscending: boolean) {
    this.activeSorting = column;
  }
}
