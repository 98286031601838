import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SidebarService } from '@core/components/sidebar/services/sidebar.service';
import { UserService } from '@shared/services/user.service';
import { Observable, Subject } from 'rxjs';
import { LoadingComponent } from '@shared/components/base/loading/loading.component';
import { UsersService } from '@shared/services/users.service';

@Component({
  selector: 'pn-shell',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SidebarComponent, LoadingComponent],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
})
export class ShellComponent implements OnInit, OnDestroy {
  isSidebarCollapsed: boolean = false;
  loading$!: Observable<boolean>;
  private destroy$ = new Subject<void>();
  constructor(
    private sidebarService: SidebarService,
    private userService: UserService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.loading$ = this.userService.loading$;
    this.sidebarService.isCollapsed$.subscribe((collapsed) => {
      this.isSidebarCollapsed = collapsed;
    });

    this.userService.refreshUser();
    this.usersService.users = JSON.parse(localStorage.getItem('users') || '[]');
  }

  initUser() {
    this.userService.refreshUser();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
