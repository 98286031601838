import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadCardComponent } from '@shared/components/form-elements/file/file-upload/components/file-upload-card/file-upload-card.component';
import { ButtonComponent } from '@shared/components/base/button/button.component';
import { ErrorComponent } from '@shared/components/form-elements/error/error.component';

@NgModule({
  declarations: [FileUploadComponent, FileUploadCardComponent],
  imports: [CommonModule, ButtonComponent, ErrorComponent, NgOptimizedImage],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
