<button
  class="sidebar-profile-menu__button sidebar-profile-menu__button--first"
  (click)="onMenuClose()"
>
  <span class="sidebar-profile-menu__button-text">{{ getName() }}</span>
  <span class="sidebar-profile-menu__button-icon">
    <pn-icon icon="angle-bottom"></pn-icon>
  </span>
</button>
@for (user of users; track user) {
  <button
    class="sidebar-profile-menu__button"
    (click)="onMenuSelect(user.token)"
  >
    <span class="sidebar-profile-menu__button-text">{{ user.name }}</span>
  </button>
}
<button class="sidebar-profile-menu__add" (click)="onAddUser()">
  <span class="sidebar-profile-menu__add-icon">
    <pn-icon icon="plus"></pn-icon>
  </span>
  <span>Добавить пользователя</span>
</button>
<div class="sidebar-profile-menu__bottom">
  <button class="sidebar-profile-menu__button" (click)="onLogout()">
    <pn-icon icon="exit" width="24"></pn-icon>
    <span>Выйти из аккаунта</span>
  </button>
</div>
