import { Component, input } from '@angular/core';
import { FileUploadItem } from '@shared/components/form-elements/file/file-upload/models/file-upload-item.model';

@Component({
  selector: 'pn-file-upload-card',
  templateUrl: './file-upload-card.component.html',
  styleUrl: './file-upload-card.component.scss',
})
export class FileUploadCardComponent {
  file = input.required<FileUploadItem>();

  getFileIcon() {
    const extension = this.file().file.name.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'image';
      default:
        return 'file';
    }
  }
}
