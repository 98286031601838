import { Component, OnInit } from '@angular/core';
import { AuthHeadComponent } from '@app/auth/components/auth-head/auth-head.component';
import { TitleH1Component } from '@shared/components/titles/title-h1/title-h1.component';
import { AuthBodyComponent } from '@app/auth/components/auth-body/auth-body.component';
import { AuthBottomComponent } from '@app/auth/components/auth-bottom/auth-bottom.component';
import { Router, RouterLink } from '@angular/router';
import {
  AuthTypeEnum,
  SignInTabsComponent,
} from '@app/auth/sign-in/components/sign-in-tabs/sign-in-tabs.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextComponent } from '@shared/components/form-elements/input-text/input-text.component';
import { IconComponent } from '@shared/components/base/icon/icon.component';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '@shared/components/base/button/button.component';
import { SignInLogin } from '@app/auth/sign-in/models/sign-in-login.model';
import { AuthService } from '@app/auth/services/auth.service';
import { FormFieldModule } from '@shared/components/form-elements/form-field/form-field.module';
import { CheckboxModule } from '@shared/components/form-elements/checkbox/checkbox.module';
import { UsersService } from '@shared/services/users.service';
import { getName } from '@core/components/sidebar/utils/get-name.utils';

interface SignInForm {
  email: FormControl<string>;
  phone: FormControl<string>;
  password: FormControl<string>;
  remember_me: FormControl<boolean>;
}

@Component({
  selector: 'pn-sign-in',
  standalone: true,
  imports: [
    AuthHeadComponent,
    TitleH1Component,
    AuthBodyComponent,
    AuthBottomComponent,
    RouterLink,
    SignInTabsComponent,
    ReactiveFormsModule,
    InputTextComponent,
    IconComponent,
    NgIf,
    CheckboxModule,
    ButtonComponent,
    FormFieldModule,
  ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
})
export class SignInComponent implements OnInit {
  authType: AuthTypeEnum = AuthTypeEnum.Phone;
  authTypeEnum = AuthTypeEnum;
  form!: FormGroup<SignInForm>;
  isShowPassword: boolean = false;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: Router,
    private usersService: UsersService
  ) {}
  ngOnInit() {
    this.createForm();
  }

  changeTab(authType: AuthTypeEnum) {
    this.authType = authType;
    this.form.reset();

    if (authType === AuthTypeEnum.Phone) {
      this.form.controls.email.clearValidators();
      this.form.controls.password.clearValidators();
      this.form.controls.phone.setValidators([Validators.required]);
    } else {
      this.form.controls.phone.clearValidators();
      this.form.controls.email.setValidators([Validators.required]);
      this.form.controls.password.setValidators([Validators.required]);
    }

    this.form.controls.email.updateValueAndValidity();
    this.form.controls.password.updateValueAndValidity();
    this.form.controls.phone.updateValueAndValidity();
  }

  createForm() {
    this.form = this.fb.group<SignInForm>({
      email: this.fb.nonNullable.control(''),
      phone: this.fb.nonNullable.control('', [Validators.required]),
      password: this.fb.nonNullable.control(''),
      remember_me: this.fb.nonNullable.control(false),
    });
  }

  submitFormLogin() {
    if (this.form.invalid) {
      return;
    }

    const model: SignInLogin = {
      email: this.form.getRawValue().email,
      password: this.form.getRawValue().password,
      remember_me: this.form.getRawValue().remember_me,
    };

    this.loading = true;

    this.authService.signInLogin(model).subscribe({
      next: (user) => {
        this.loading = false;
        const token = this.authService.getToken();
        if (user && token) {
          this.usersService.addUser(token, getName(user));
          this.route.navigate(['/']);
        }
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  submitFormPhone() {
    if (this.form.invalid) {
      return;
    }
  }
}
