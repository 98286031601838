import { Component, output } from '@angular/core';
import { IconComponent } from '@shared/components/base/icon/icon.component';
import { ButtonComponent } from '@shared/components/base/button/button.component';
import { SidebarBaseComponent } from '@core/components/sidebar/components/sidebar-base.component';
import { getName } from '@core/components/sidebar/utils/get-name.utils';
import { UsersService } from '@shared/services/users.service';
import { Users } from '@shared/models/user';
import { AuthService } from '@app/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pn-sidebar-profile-menu',
  standalone: true,
  imports: [IconComponent, ButtonComponent],
  templateUrl: './sidebar-profile-menu.component.html',
  styleUrl: './sidebar-profile-menu.component.scss',
  host: {
    class: 'sidebar-profile-menu',
  },
})
export class SidebarProfileMenuComponent extends SidebarBaseComponent {
  menuSelect = output<string>();
  menuClose = output<void>();
  logout = output<void>();
  users: Users[] = [];

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private router: Router
  ) {
    super();
    this.usersService.users$.subscribe((users) => {
      const token = this.authService.getToken();
      if (token) {
        this.users = users.filter((u) => u.token !== token);
      } else {
        this.users = users;
      }
    });
  }

  getName(): string {
    return getName(this.user);
  }

  onMenuSelect(token: string) {
    this.usersService.setActiveUser(token);
    this.menuSelect.emit(token);
  }

  onMenuClose() {
    this.menuClose.emit();
  }

  onLogout() {
    this.logout.emit();
    this.authService.logout();
  }

  onAddUser() {
    this.onMenuClose();
    this.router.navigate(['/sign-in']);
  }
}
