import { NavigationItemModel } from '@core/components/sidebar/components/sidebar-navigation/components/navigation-item/models/sidebar-navigation-item.model';

export const SIDEBAR_NAVIGATION_ITEMS: NavigationItemModel[] = [
  {
    text: 'Профиль',
    icon: 'navigation/profile',
    link: 'profile',
    permission: '',
  },
  {
    text: 'Источники образования отходов',
    icon: 'navigation/wastes-sources',
    link: 'wastes-sources',
    permission: 'wastes-sources.read',
  },
  {
    text: 'Транспортные средства',
    icon: 'navigation/transports',
    link: 'transports',
    permission: 'transports.read',
  },
  {
    text: 'Управление пользователями',
    icon: 'navigation/user-management',
    link: 'users',
    permission: 'users.read',
  },
  {
    text: 'Управление интеграциями',
    icon: 'navigation/integration-management',
    link: 'integrations',
    permission: 'integrations.read',
  },
  {
    text: 'Справочник полигонов',
    icon: 'navigation/dictionary-management',
    link: 'dictionary-polygons',
    permission: 'dictionary-polygons.read',
  },
  {
    text: 'Справочник транспортных средств',
    icon: 'navigation/dictionary-management',
    link: 'dictionary-transports',
    permission: 'dictionary-transports.read',
  },
  {
    text: 'Справочник отходов',
    icon: 'navigation/dictionary-management',
    link: 'dictionary-wastes',
    permission: 'dictionary-wastes.read',
  },
  {
    text: 'Справочник источников образования отходов',
    icon: 'navigation/dictionary-management',
    link: 'dictionary-wastes-sources',
    permission: 'dictionary-wastes-sources.read',
  },
  {
    text: 'Управление уведомлениями',
    icon: 'navigation/notifications-management',
    link: 'notifications-settings',
    permission: 'notifications-settings.read',
  },
  {
    text: 'Заявки',
    icon: 'navigation/reports',
    link: 'requests',
    permission: 'requests.read',
  },
  {
    text: 'Договоры',
    icon: 'navigation/contracts',
    link: 'contracts',
    permission: 'contracts.read',
  },
  {
    text: 'Отчетность',
    icon: 'navigation/reports',
    link: 'reports',
    permission: 'reports.read',
  },
  {
    text: 'Уведомления',
    icon: 'navigation/notifications',
    link: 'notifications',
    permission: 'notifications.read',
  },
];
