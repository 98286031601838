import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileUpload } from '@shared/components/form-elements/file/file-upload/models/file-upload.model';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  uploadFile(uploadUrl: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<FileUpload>(uploadUrl, formData, {
      headers: {
        'x-skip-json': 'true',
      },
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteFile(deleteUrl: string) {
    const url = `${deleteUrl}`;
    return this.http.delete(url);
  }
}
