<a
  [attr.href]="file().uploadedFile?.url || null"
  target="_blank"
  class="file-icon"
>
  <img ngSrc="/assets/images/files/pdf.svg" width="28" height="26" alt="" />
</a>
<a
  [attr.href]="file().uploadedFile?.url || null"
  target="_blank"
  class="file-caption"
>
  <span class="file-name">
    {{ file().file.name }}
  </span>
  @if (file().isUploading) {
    <span class="file-progress">
      <span
        class="file-progress__value"
        [style.width.%]="file().uploadProgress"
      ></span>
    </span>
  }
  @if (!file().isUploading && file().uploadedFile?.url) {
    <div class="file-download">Скачать</div>
  }
</a>
<pn-button
  *ngIf="!file().isUploading"
  theme="none"
  icon="remove"
  iconWidth="16"
  iconHeight="16"
  class="file-remove"
></pn-button>
