import { Component, input, output } from '@angular/core';
import { FileUploadItem } from '@shared/components/form-elements/file/file-upload/models/file-upload-item.model';
import { FileUploadService } from '@shared/components/form-elements/file/file-upload/services/file-upload.service';
import { HttpEventType } from '@angular/common/http';
import { FileBaseComponent } from '@shared/components/form-elements/file/file-base/file-base.component';

@Component({
  selector: 'pn-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent extends FileBaseComponent {
  uploadUrl = input.required<string>();
  deleteUrl = input<string | null>(null);
  uploadSuccess = output<{
    file: FileUploadItem | null;
    files: FileUploadItem[];
  }>();

  isUploading: boolean = false;
  files: FileUploadItem[] = [];

  constructor(private fileUploadService: FileUploadService) {
    super();
  }

  onFileSelected(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const selectedFiles = target.files as FileList;
    this.resetErrors();

    if (selectedFiles.length === 0) {
      return;
    }

    const multiple = this.multiple();

    const filesToUpload = multiple
      ? Array.from(selectedFiles)
      : [selectedFiles[0]];

    this.checkMaxFiles(filesToUpload.length);

    if (this.hasErrors()) {
      return;
    }

    filesToUpload.forEach((file: File) => {
      this.checkErrors(file);

      if (this.hasErrors()) {
        return;
      }

      const fileItem: FileUploadItem = {
        file,
        uploadProgress: 0,
        isUploading: true,
      };

      this.files.push(fileItem);

      this.fileSelected.emit(selectedFiles);
      this.uploadFile(fileItem);
    });

    target.value = '';
  }

  uploadFile(fileItem: FileUploadItem) {
    const file = fileItem.file;

    this.fileUploadService.uploadFile(this.uploadUrl(), file).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          fileItem.uploadProgress = Math.round(
            (100 * event.loaded) / (event.total || 1)
          );
        } else if (event.type === HttpEventType.Response) {
          const response = event.body;
          fileItem.uploadedFile = {
            name: response?.name || file.name,
            url: response?.url,
          };
          fileItem.isUploading = false;
          fileItem.uploadProgress = 100;

          this.checkAllUploadsComplete();
        }
      },
      error: () => {
        fileItem.error = 'Произошла ошибка при загрузке файла';
        fileItem.isUploading = false;

        this.checkAllUploadsComplete();
      },
    });
  }

  checkAllUploadsComplete() {
    const uploading = this.files.some((fileItem) => fileItem.isUploading);
    if (!uploading) {
      this.isUploading = false;

      const hasErrors = this.files.some((fileItem) => fileItem.error);

      if (!hasErrors) {
        this.uploadSuccess.emit({
          file: null,
          files: this.getUploadedFiles(),
        });
      } else {
        console.error('error');
      }
    }
  }

  deleteFile(fileItem: FileUploadItem) {
    const deleteUrl = this.deleteUrl();
    if (!deleteUrl || !fileItem.uploadedFile) {
      return;
    }

    this.fileUploadService.deleteFile(deleteUrl).subscribe({
      next: () => {
        this.files = this.files.filter((f) => f !== fileItem);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  isMaxFiles(): boolean {
    if (!this.multiple()) {
      return this.files.length > 0;
    } else {
      const maxFiles = this.maxFiles();
      if (!maxFiles) {
        return false;
      }
      return this.files.length >= maxFiles;
    }
  }

  getUploadedFiles(): FileUploadItem[] {
    return this.files.filter((fileItem) => fileItem.uploadedFile);
  }
}
