<div class="file-list" [class.empty]="files.length === 0">
  @for (file of files; track file) {
    <pn-file-upload-card [file]="file"></pn-file-upload-card>
  }
</div>
<pn-error *ngIf="fileUploadErrors.maxFiles" class="file-error"
  >Превышено максимальное количество файлов</pn-error
>
<pn-error *ngIf="fileUploadErrors.allowedExtensions" class="file-error"
  >Тип файла не поддерживается</pn-error
>
<pn-error *ngIf="fileUploadErrors.maxFileSize" class="file-error"
  >Размер файла превышает максимально допустимый {{ maxFileSize() }}</pn-error
>
<pn-button
  theme="primary"
  modifier="outline"
  (buttonClick)="inputElement.click()"
  [disabled]="isUploading"
  *ngIf="!isMaxFiles()"
  >Загрузить файл</pn-button
>
<input
  #inputElement
  type="file"
  class="visually-hidden"
  tabindex="-1"
  [accept]="allowedExtensions()"
  [multiple]="multiple()"
  (change)="onFileSelected($event)"
/>
