import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { Router } from '@angular/router';
import { ErrorsService } from '@core/services/errors.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const errorService = inject(ErrorsService);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        authService.logout();
      }

      if (error.status === 403) {
        inject(Router).navigate(['/']);
      }

      if (error.url?.includes('/users/profile') && error.status === 404) {
        authService.logout();
      }

      // const errorResponse = error.error as ResponseError;

      if (error.status !== 404) {
        if (typeof error.error === 'string') {
          errorService.addError(`${error.status} ${error.error}`);
        } else if (Array.isArray(error.error)) {
          error.error.forEach((errorText) =>
            errorService.addError(`${error.status} ${errorText}`)
          );
        } else {
          errorService.addError(`${error.status} ${error.statusText}`);
        }
      }

      return throwError(() => error);
    })
  );
};
