import { Component, input, output } from '@angular/core';
import { FileErrors } from '@shared/components/form-elements/file/file-base/models/file-errors.model';

@Component({
  selector: 'pn-file-base',
  standalone: true,
  imports: [],
  template: '',
  styles: '',
})
export class FileBaseComponent {
  multiple = input<boolean>(false);
  maxFiles = input<number | null>(null);
  allowedExtensions = input<string | null>(null);
  maxFileSize = input<number | null>(null);

  fileSelected = output<FileList>();
  fileDeleted = output<number>();

  fileUploadErrors: FileErrors = {
    maxFileSize: false,
    maxFiles: false,
    allowedExtensions: false,
  };

  checkMaxFiles(filesLength: number) {
    const maxFiles = this.maxFiles();
    if (maxFiles && filesLength > maxFiles) {
      this.fileUploadErrors.maxFiles = true;
    }
  }

  checkErrors(file: File) {
    const allowedExtensions = this.allowedExtensions();
    const maxFileSize = this.maxFileSize();

    if (maxFileSize && file.size > maxFileSize) {
      this.fileUploadErrors.maxFileSize = true;
    }

    if (allowedExtensions && !allowedExtensions.includes(file.type)) {
      this.fileUploadErrors.allowedExtensions = true;
    }
  }

  resetErrors() {
    this.fileUploadErrors = {
      maxFileSize: false,
      maxFiles: false,
      allowedExtensions: false,
    };
  }

  hasErrors(): boolean {
    return (
      this.fileUploadErrors.maxFileSize ||
      this.fileUploadErrors.maxFiles ||
      this.fileUploadErrors.allowedExtensions
    );
  }
}
