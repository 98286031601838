/* src/app/_shared/components/form-elements/file/file-upload/components/file-upload-card/file-upload-card.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  display: flex;
  align-items: center;
  max-width: 185px;
}
.file-icon {
  flex: 0 0 28px;
  text-decoration: none;
}
.file-icon:hover {
  text-decoration: none;
}
.file-caption {
  min-width: 0;
  flex-shrink: 1;
  display: block;
  text-decoration: none;
  padding-left: 10px;
}
.file-caption:hover {
  text-decoration: none;
}
.file-name {
  flex-grow: 0;
  font-size: 10px;
  line-height: 1;
  color: #212121;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}
.file-download {
  opacity: 0.5;
  font-size: 10px;
  line-height: 1;
  color: #212121;
  display: block;
}
.file-progress {
  border-radius: 3px;
  background-color: #ced2da;
  height: 6px;
  width: 100%;
  display: block;
}
.file-progress__value {
  height: 6px;
  border-radius: 3px;
  background-color: #338054;
  width: 0;
  animation: width 0.3s ease;
  display: block;
}
.file-remove {
  flex: 0 0 28px;
}
/*# sourceMappingURL=file-upload-card.component.css.map */
